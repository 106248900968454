var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aRow',{attrs:{"gutter":15}},[_c('aCol',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer travel-multiselector"},[_c('label',{class:'filled'},[_vm._v(" Fornecedor ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `contract_finances_flight_${_vm.flight.id}_supplier_id`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `contract_finances_flight_${flight.id}_supplier_id`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%","height":"30px"},attrs:{"show-search":"","allow-clear":true,"disabled":_vm.isSale,"optionFilterProp":"txt","placeholder":"Selecione"},on:{"change":function($event){return _vm.onSelectSupplier(_vm.flight.id)}}},_vm._l((_vm.productSupplier.list),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.trading_name}},[_vm._v("\n            "+_vm._s(item.id)+" -\n            "+_vm._s(item.trading_name.toUpperCase())+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":5}},[_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `contract_finances_flight_${_vm.flight.id}_company_credit_card_active`,
          ]),expression:"[\n            `contract_finances_flight_${flight.id}_company_credit_card_active`,\n          ]"}],attrs:{"checked":_vm.tempContract[
              `contract_finances_flight_${_vm.flight.id}_company_credit_card_active`
            ] == 1
              ? true
              : false}},[_c('span',{staticClass:"f12"},[_c('b',[_vm._v("C. Credito Corporativo")])])])],1)],1),_vm._v(" "),_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(
        ['1', 1, true].includes(
          _vm.tempContract[
            `contract_finances_flight_${_vm.flight.id}_company_credit_card_active`
          ]
        )
      ),expression:"\n        ['1', 1, true].includes(\n          tempContract[\n            `contract_finances_flight_${flight.id}_company_credit_card_active`\n          ]\n        )\n      "}],attrs:{"span":4}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Valor retirado do cartão")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `contract_finances_flight_${_vm.flight.id}_company_credit_card_value`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `contract_finances_flight_${flight.id}_company_credit_card_value`,\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Digite"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(
        ['1', 1, true].includes(
          _vm.tempContract[
            `contract_finances_flight_${_vm.flight.id}_company_credit_card_active`
          ]
        )
      ),expression:"\n        ['1', 1, true].includes(\n          tempContract[\n            `contract_finances_flight_${flight.id}_company_credit_card_active`\n          ]\n        )\n      "}],attrs:{"span":7}},[_c('a-form-item',{staticClass:"travel-input-outer travel-multiselector"},[_c('label',{class:'filled'},[_vm._v(" Cartão de Crédito ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `contract_finances_flight_${_vm.flight.id}_company_credit_card_id`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `contract_finances_flight_${flight.id}_company_credit_card_id`,\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%","height":"30px"},attrs:{"show-search":"","allow-clear":true,"disabled":_vm.isSale,"optionFilterProp":"txt","placeholder":"Selecione"}},_vm._l((_vm.companyCreditCards.list),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.card_name}},[_vm._v("\n            "+_vm._s(item.id)+" - "+_vm._s(item.card_name.split(" ")[0])+" |\n            "+_vm._s(item.card_number)+"\n          ")])}),1)],1)],1)],1),_vm._v(" "),_c('aRow',{staticClass:"head-line",attrs:{"gutter":15}},[_c('aCol',{attrs:{"span":1}},[_vm._v(" # ")]),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[_vm._v(" Tipo ")]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_vm._v(" Tarifa ")]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_vm._v(" Taxa Embarque ")]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_vm._v(" Taxa DU ")]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_vm._v(" Teb Agencia/RAV ")]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_vm._v(" TOTAL ")])],1),_vm._v(" "),_vm._l((_vm.flight.travellers),function(traveller,index){return _c('aRow',{key:index,staticClass:"line",attrs:{"gutter":15}},[_c('aCol',{attrs:{"span":1}},[_c('span',{staticClass:"user"},[_c('a-icon',{attrs:{"type":"user"}}),_vm._v(" "),_c('span',{staticClass:"number"},[_vm._v(" "+_vm._s(traveller.id)+" ")])],1)]),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[_c('div',{staticClass:"col"},[_vm._v("\n        "+_vm._s(_vm.tempContract[`traveller_${traveller.id}_age`] == "adult"
            ? "ADT"
            : "CHD")+"\n      ")])]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `flight_${_vm.flight.id}_traveller_${traveller.id}_value`,
            {
              initialValue:
                _vm.tempContract[
                  `flight_${_vm.flight.id}_traveller_${traveller.id}_value`
                ] != undefined
                  ? _vm.tempContract[
                      `flight_${_vm.flight.id}_traveller_${traveller.id}_value`
                    ]
                  : undefined,
            },
          ]),expression:"[\n            `flight_${flight.id}_traveller_${traveller.id}_value`,\n            {\n              initialValue:\n                tempContract[\n                  `flight_${flight.id}_traveller_${traveller.id}_value`\n                ] != undefined\n                  ? tempContract[\n                      `flight_${flight.id}_traveller_${traveller.id}_value`\n                    ]\n                  : undefined,\n            },\n          ]"},{name:"currency",rawName:"v-currency"}],staticClass:"travel-input upper",attrs:{"placeholder":"Digite","disabled":_vm.isSaleBlockedField},on:{"blur":function($event){return _vm.calcRegularFlight()}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `flight_${_vm.flight.id}_traveller_${traveller.id}_taxes`,
            {
              initialValue:
                _vm.tempContract[
                  `flight_${_vm.flight.id}_traveller_${traveller.id}_taxes`
                ] != undefined
                  ? _vm.tempContract[
                      `flight_${_vm.flight.id}_traveller_${traveller.id}_taxes`
                    ]
                  : undefined,
            },
          ]),expression:"[\n            `flight_${flight.id}_traveller_${traveller.id}_taxes`,\n            {\n              initialValue:\n                tempContract[\n                  `flight_${flight.id}_traveller_${traveller.id}_taxes`\n                ] != undefined\n                  ? tempContract[\n                      `flight_${flight.id}_traveller_${traveller.id}_taxes`\n                    ]\n                  : undefined,\n            },\n          ]"},{name:"currency",rawName:"v-currency"}],staticClass:"travel-input upper",attrs:{"placeholder":"Digite","disabled":_vm.isSale},on:{"blur":function($event){return _vm.calcRegularFlight()}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `flight_${_vm.flight.id}_traveller_${traveller.id}_du_tax`,
            {
              initialValue:
                _vm.tempContract[
                  `flight_${_vm.flight.id}_traveller_${traveller.id}_du_tax`
                ] != undefined
                  ? _vm.tempContract[
                      `flight_${_vm.flight.id}_traveller_${traveller.id}_du_tax`
                    ]
                  : undefined,
            },
          ]),expression:"[\n            `flight_${flight.id}_traveller_${traveller.id}_du_tax`,\n            {\n              initialValue:\n                tempContract[\n                  `flight_${flight.id}_traveller_${traveller.id}_du_tax`\n                ] != undefined\n                  ? tempContract[\n                      `flight_${flight.id}_traveller_${traveller.id}_du_tax`\n                    ]\n                  : undefined,\n            },\n          ]"},{name:"currency",rawName:"v-currency"}],staticClass:"travel-input upper",attrs:{"placeholder":"Digite","disabled":_vm.isSale},on:{"blur":function($event){return _vm.calcRegularFlight()}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `flight_${_vm.flight.id}_traveller_${traveller.id}_teb_agency`,
            {
              initialValue:
                _vm.tempContract[
                  `flight_${_vm.flight.id}_traveller_${traveller.id}_teb_agency`
                ] != undefined
                  ? _vm.tempContract[
                      `flight_${_vm.flight.id}_traveller_${traveller.id}_teb_agency`
                    ]
                  : undefined,
            },
          ]),expression:"[\n            `flight_${flight.id}_traveller_${traveller.id}_teb_agency`,\n            {\n              initialValue:\n                tempContract[\n                  `flight_${flight.id}_traveller_${traveller.id}_teb_agency`\n                ] != undefined\n                  ? tempContract[\n                      `flight_${flight.id}_traveller_${traveller.id}_teb_agency`\n                    ]\n                  : undefined,\n            },\n          ]"},{name:"currency",rawName:"v-currency"}],staticClass:"travel-input upper",attrs:{"placeholder":"Digite","disabled":_vm.isSale},on:{"blur":function($event){return _vm.calcRegularFlight()}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('div',{staticClass:"col"},[_vm._v("\n        "+_vm._s(_vm.flightTotals.totalTravellers.length > 0
            ? _vm.flightTotals.totalTravellers[index].total
            : 0)+"\n      ")])])],1)}),_vm._v(" "),_c('aRow',{staticClass:"line total",attrs:{"gutter":15}},[_c('aCol',{attrs:{"span":1}}),_vm._v(" "),_c('aCol',{attrs:{"span":3}}),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.flightTotals.total))])]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.flightTotals.totalTax))])]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.flightTotals.totalDU))])]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.flightTotals.totalTeb))])]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.flightTotals.totalGeneral))])])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }