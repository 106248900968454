<template>
  <div>
    <aRow :gutter="15">
      <aCol :span="8">
        <a-form-item class="travel-input-outer travel-multiselector">
          <label :class="'filled'"> Fornecedor </label>
          <a-select
            class="travel-input"
            show-search
            :allow-clear="true"
            :disabled="isSale"
            optionFilterProp="txt"
            placeholder="Selecione"
            v-decorator="[
              `contract_finances_flight_${flight.id}_supplier_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            style="width: 100%; height: 30px"
            @change="onSelectSupplier(flight.id)"
          >
            <a-select-option
              v-for="(item, index) of productSupplier.list"
              :key="index"
              :value="item.id"
              :txt="item.trading_name"
            >
              {{ item.id }} -
              {{ item.trading_name.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>
      <aCol :span="5">
        <a-form-item>
          <a-checkbox
            :checked="
              tempContract[
                `contract_finances_flight_${flight.id}_company_credit_card_active`
              ] == 1
                ? true
                : false
            "
            v-decorator="[
              `contract_finances_flight_${flight.id}_company_credit_card_active`,
            ]"
            ><span class="f12"><b>C. Credito Corporativo</b></span>
          </a-checkbox>
        </a-form-item>
      </aCol>

      <aCol
        v-show="
          ['1', 1, true].includes(
            tempContract[
              `contract_finances_flight_${flight.id}_company_credit_card_active`
            ]
          )
        "
        :span="4"
      >
        <a-form-item class="travel-input-outer">
          <label class="filled">Valor retirado do cartão</label>
          <a-input
            class="travel-input"
            placeholder="Digite"
            v-currency
            v-decorator="[
              `contract_finances_flight_${flight.id}_company_credit_card_value`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </aCol>

      <aCol
        v-show="
          ['1', 1, true].includes(
            tempContract[
              `contract_finances_flight_${flight.id}_company_credit_card_active`
            ]
          )
        "
        :span="7"
      >
        <a-form-item class="travel-input-outer travel-multiselector">
          <label :class="'filled'"> Cartão de Crédito </label>
          <a-select
            class="travel-input"
            show-search
            :allow-clear="true"
            :disabled="isSale"
            optionFilterProp="txt"
            placeholder="Selecione"
            v-decorator="[
              `contract_finances_flight_${flight.id}_company_credit_card_id`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            style="width: 100%; height: 30px"
          >
            <a-select-option
              v-for="(item, index) of companyCreditCards.list"
              :key="index"
              :value="item.id"
              :txt="item.card_name"
            >
              {{ item.id }} - {{ item.card_name.split(" ")[0] }} |
              {{ item.card_number }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>
    </aRow>

    <aRow :gutter="15" class="head-line">
      <aCol :span="1"> # </aCol>
      <aCol :span="3"> Tipo </aCol>
      <aCol :span="4"> Tarifa </aCol>
      <aCol :span="4"> Taxa Embarque </aCol>
      <aCol :span="4"> Taxa DU </aCol>
      <aCol :span="4"> Teb Agencia/RAV </aCol>
      <aCol :span="4"> TOTAL </aCol>
    </aRow>

    <aRow
      v-for="(traveller, index) in flight.travellers"
      :key="index"
      :gutter="15"
      class="line"
    >
      <aCol :span="1">
        <span class="user">
          <a-icon type="user" />
          <span class="number"> {{ traveller.id }} </span>
        </span>
      </aCol>

      <aCol :span="3">
        <div class="col">
          {{
            tempContract[`traveller_${traveller.id}_age`] == "adult"
              ? "ADT"
              : "CHD"
          }}
        </div>
      </aCol>

      <aCol :span="4">
        <a-form-item class="travel-input-outer">
          <a-input
            class="travel-input upper"
            placeholder="Digite"
            v-decorator="[
              `flight_${flight.id}_traveller_${traveller.id}_value`,
              {
                initialValue:
                  tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_value`
                  ] != undefined
                    ? tempContract[
                        `flight_${flight.id}_traveller_${traveller.id}_value`
                      ]
                    : undefined,
              },
            ]"
            v-currency
            :disabled="isSaleBlockedField"
            @blur="calcRegularFlight()"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </aCol>

      <aCol :span="4">
        <a-form-item class="travel-input-outer">
          <a-input
            class="travel-input upper"
            placeholder="Digite"
            v-decorator="[
              `flight_${flight.id}_traveller_${traveller.id}_taxes`,
              {
                initialValue:
                  tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_taxes`
                  ] != undefined
                    ? tempContract[
                        `flight_${flight.id}_traveller_${traveller.id}_taxes`
                      ]
                    : undefined,
              },
            ]"
            v-currency
            :disabled="isSale"
            @blur="calcRegularFlight()"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </aCol>

      <aCol :span="4">
        <a-form-item class="travel-input-outer">
          <a-input
            class="travel-input upper"
            placeholder="Digite"
            v-decorator="[
              `flight_${flight.id}_traveller_${traveller.id}_du_tax`,
              {
                initialValue:
                  tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_du_tax`
                  ] != undefined
                    ? tempContract[
                        `flight_${flight.id}_traveller_${traveller.id}_du_tax`
                      ]
                    : undefined,
              },
            ]"
            v-currency
            :disabled="isSale"
            @blur="calcRegularFlight()"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </aCol>

      <aCol :span="4">
        <a-form-item class="travel-input-outer">
          <a-input
            class="travel-input upper"
            placeholder="Digite"
            v-decorator="[
              `flight_${flight.id}_traveller_${traveller.id}_teb_agency`,
              {
                initialValue:
                  tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_teb_agency`
                  ] != undefined
                    ? tempContract[
                        `flight_${flight.id}_traveller_${traveller.id}_teb_agency`
                      ]
                    : undefined,
              },
            ]"
            v-currency
            :disabled="isSale"
            @blur="calcRegularFlight()"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </aCol>

      <aCol :span="4">
        <div class="col">
          {{
            flightTotals.totalTravellers.length > 0
              ? flightTotals.totalTravellers[index].total
              : 0
          }}
        </div>
      </aCol>
    </aRow>

    <aRow :gutter="15" class="line total">
      <aCol :span="1"> </aCol>
      <aCol :span="3"> </aCol>
      <aCol :span="4">
        <div class="col">{{ flightTotals.total }}</div>
      </aCol>
      <aCol :span="4">
        <div class="col">{{ flightTotals.totalTax }}</div>
      </aCol>
      <aCol :span="4">
        <div class="col">{{ flightTotals.totalDU }}</div>
      </aCol>
      <aCol :span="4">
        <div class="col">{{ flightTotals.totalTeb }}</div>
      </aCol>
      <aCol :span="4">
        <div class="col">{{ flightTotals.totalGeneral }}</div>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";

import formatThings from "@/mixins/general/formatThings";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import companyCreditCardMixins from "@/mixins/company-credit-cards/companyCreditCardMixins.js";

export default {
  name: "ContractFinancesRegularFlight",
  mixins: [formatThings, productSupplierMixins, companyCreditCardMixins],
  props: {
    tempContract: Object,
    form: Object,
    flight: Object,
    isSale: Boolean,
    isSaleBlockedField: Boolean,
  },
  data() {
    return {
      flightSectionRows: [],
      flightTotals: {
        total: 0,
        totalTax: 0,
        totalDU: 0,
        totalTeb: 0,
        totalGeneral: 0,
        totalTravellers: [],
      },
    };
  },
  beforeMount() {
    this.productSupplier.pagination.perPage = 300;
    this.productSupplier.filters.only_specific_categories = 1;
    this.getProductSuppliers();
  },
  mounted() {
    this.flightSectionRows = this.tempContract.flight_sections_rows
      ? JSON.parse(this.tempContract.flight_sections_rows)
      : [];

    this.form.setFieldsValue({
      [`contract_finances_flight_${this.flight.id}_supplier_id`]:
        this.tempContract[
          `contract_finances_flight_${this.flight.id}_supplier_id`
        ],

      [`contract_finances_flight_${this.flight.id}_company_credit_card_value`]:
        this.tempContract[
          `contract_finances_flight_${this.flight.id}_company_credit_card_value`
        ],

      [`contract_finances_flight_${this.flight.id}_company_credit_card_id`]:
        this.tempContract[
          `contract_finances_flight_${this.flight.id}_company_credit_card_id`
        ],
    });

    this.calcRegularFlight();
    this.companyCreditCards.filters.status.selected = "Ativo";
    this.getCompanyCreditCards();
  },
  methods: {
    calcRegularFlight() {
      let total = 0,
        totalTax = 0,
        totalDU = 0,
        totalTeb = 0,
        totalGeneral = 0,
        totalTravellers = [];

      if (this.tempContract[`flight_${this.flight.id}_type`] == "Regular") {
        this.flightSectionRows[this.flight.id - 1].travellers.forEach(
          (traveller) => {
            total += parse(
              this.tempContract[
                `flight_${this.flight.id}_traveller_${traveller.id}_value`
              ]
            );

            totalTax += parse(
              this.tempContract[
                `flight_${this.flight.id}_traveller_${traveller.id}_taxes`
              ]
            );

            totalDU += parse(
              this.tempContract[
                `flight_${this.flight.id}_traveller_${traveller.id}_du_tax`
              ]
            );

            totalTeb += parse(
              this.tempContract[
                `flight_${this.flight.id}_traveller_${traveller.id}_teb_agency`
              ]
            );

            totalTravellers.push({
              id: traveller.id,
              total: this.formatPricePtBr(
                parse(
                  this.tempContract[
                    `flight_${this.flight.id}_traveller_${traveller.id}_teb_agency`
                  ]
                ) +
                  parse(
                    this.tempContract[
                      `flight_${this.flight.id}_traveller_${traveller.id}_du_tax`
                    ]
                  ) +
                  parse(
                    this.tempContract[
                      `flight_${this.flight.id}_traveller_${traveller.id}_taxes`
                    ]
                  ) +
                  parse(
                    this.tempContract[
                      `flight_${this.flight.id}_traveller_${traveller.id}_value`
                    ]
                  )
              ),
            });
          }
        );

        this.tempContract[`flight_${this.flight.id}_total_price`] =
          total + totalTax + totalDU + totalTeb;
      }

      totalGeneral += total + totalTax + totalDU + totalTeb;

      this.flightTotals = {
        total: this.formatPricePtBr(total),
        totalTax: this.formatPricePtBr(totalTax),
        totalDU: this.formatPricePtBr(totalDU),
        totalTeb: this.formatPricePtBr(totalTeb),
        totalGeneral: this.formatPricePtBr(totalGeneral),
        totalTravellers,
      };
    },
    onSelectSupplier(id) {
      setTimeout(() => {
        let supplierId =
          this.tempContract[`contract_finances_flight_${id}_supplier_id`];

        let theSupplier = this.productSupplier.list.filter((supplier) => {
          return supplier.id == supplierId;
        });

        this.tempContract[`contract_finances_flight_${id}_supplier`] =
          theSupplier[0].trading_name;
      }, 200);
    },
  },
};
</script>

<style lang="sass" scoped>
.head-line
    font-size: 10px
    font-weight: 700
    text-align: center
.line.total
  position: relative
  top: -15px
  font-weight: 700
.line
  .col
    background: #eee
    padding: 5px
    border-radius: 3px
    position: relative
    top: 5px
    font-size: 12px
    font-weight: 600
    text-align: center
    text-transform: uppercase
.user
        position: relative
        top: 10px
        font-size: 18px
        font-weight: 600
        color: #be4178
        .number
            position: absolute
            right: -8px
            top: 0
            font-size: 11px
            color: #333
</style>
